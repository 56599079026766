import React from "react";
import { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import {
  ApplicationPaths,
  QueryParameterNames,
} from "./ApiAuthorizationConstants";
import authService from "../../../api/authService";
import { IAuthorizeRouteProps } from "./types/IAuthorizeRouteProps";
import Notfound from "../Notfound";

export default class AuthorizeRoute extends Component<
  IAuthorizeRouteProps,
  { ready: boolean; authenticated: boolean; role: string }
> {
  _subscription: number | undefined | null;
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false,
      role: "",
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() =>
      this.authenticationChanged()
    );
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated, role } = this.state;
    var link = document.createElement("a");
    link.href = this.props.path as string;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURIComponent(returnUrl)}`;
    if (!ready) {
      return <div></div>;
    } else {
      const { component: Component, roles, ...rest } = this.props;
      if (!Component) return <div></div>;
      return (
        <Route
          {...rest}
          render={(props) => {
            if (authenticated) {

              if (!roles || (roles && roles.some((x) => x === role))){
                return <Component {...(props as any)} />;
              }else{
                return <Notfound/>;
              }
            } else {
              return <Redirect to={redirectUrl} />;
            }
          }}
        />
      );
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    const user = await authService.getUser();
    this.setState({ ready: true, authenticated, role: user?.role });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false, role: "" });
    await this.populateAuthenticationState();
  }
}

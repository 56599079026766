import LoadingOverlay from "components/shared/LoadingOverlay";
import { lazy, Suspense, useMemo } from "react";

const LoadFlashcardsReviewRoutes = () => {
  const FlashcardsReviewRoutes = useMemo(
    () => lazy(() => import("../FlashcardsReviewRoutes")),
    []
  );

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <FlashcardsReviewRoutes />
    </Suspense>
  );
};

export default LoadFlashcardsReviewRoutes;

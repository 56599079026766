import { AxiosRequestConfig } from "axios";
import authService from "../authService";

export const authTokenInterceptor = async (config: AxiosRequestConfig) => {
  const accessToken = await authService.getAccessToken();
  if (accessToken && config?.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

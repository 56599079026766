import LoadingOverlay from "components/shared/LoadingOverlay";
import { lazy, Suspense, useMemo } from "react";

const LoadAccountRoutes = () => {
  const AccountRoutes = useMemo(
    () => lazy(() => import("../AccountRoutes")),
    []
  );

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <AccountRoutes />
    </Suspense>
  );
};

export default LoadAccountRoutes;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component } from "react";
import { Layout } from "./components/shared/Layout";
import "./styles/custom-fonts.css";
import "./styles/custom.scss";
import { GlobalProvider } from "./contexts/GlobalContext";
import { ToastProvider } from "./contexts/ToastContext";
import {} from "./core/types/IntrinsicElements";
import { FlashcardsProvider } from "contexts/FlashcardsContext";
import { DashboardProvider } from "contexts/DashboardContext";
import AppRoutes from "routes/AppRoutes";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <GlobalProvider>
        <ToastProvider>
          <FlashcardsProvider>
            <DashboardProvider>
              <Layout>
                <AppRoutes />
              </Layout>
            </DashboardProvider>
          </FlashcardsProvider>
        </ToastProvider>
      </GlobalProvider>
    );
  }
}

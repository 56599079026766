import LoadingOverlay from "components/shared/LoadingOverlay";
import { lazy, Suspense, useMemo } from "react";

const LoadAdminRoutes = () => {
  const AdminRoutes = useMemo(
    () => lazy(() => import("../admin/AdminRoutes")),
    []
  );

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <AdminRoutes />
    </Suspense>
  );
};

export default LoadAdminRoutes;

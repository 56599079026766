import { useGlobalContext } from "../../contexts/GlobalContext";
import { IGlobalProvider } from "../../contexts/types/IGlobalProvider";
import HomeGeneric from "./HomeGeneric";
import LoadHomeLogged from "./loaders/LoadHomeLogged";

const Home = () => {
  const { globalState }: IGlobalProvider = useGlobalContext();
  if (globalState.userProfile.isAuthenticated) {
    return <LoadHomeLogged />;
  }
  return <HomeGeneric />;
};

export default Home;

import LoadingOverlay from "components/shared/LoadingOverlay";
import { lazy, Suspense, useMemo } from "react";

const LoadDecksRoutes = () => {
  const DecksRoutes = useMemo(() => lazy(() => import("../DecksRoutes")), []);

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <DecksRoutes />
    </Suspense>
  );
};

export default LoadDecksRoutes;

import {
  Intent,
  Button,
  Divider,
  Classes,
  Icon,
  Popover,
} from "@blueprintjs/core";
import { NavLink } from "react-router-dom";

import { IGlobalProvider } from "contexts/types/IGlobalProvider";
import { useGlobalContext } from "contexts/GlobalContext";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";

const NavMenuLogin = ({
  handleClickSettings,
}: {
  handleClickSettings?: () => void;
}) => {
  const { globalState }: IGlobalProvider = useGlobalContext();
  const { user, isAuthenticated, avatarImage } = globalState.userProfile!;

  return (
    <>
      {isAuthenticated ? (
        <>
          <div className="d-flex align-items-center  d-md-none">
            <NavLink to="/account/manage" className="text-decoration-none">
              <Button intent={Intent.PRIMARY} minimal={true}>
                {!avatarImage ? (
                  <span className="d-flex flex-column">
                    <span className="text-left bp5-text-small">Hola,</span>
                    <span className="fw-bolder">
                      {user?.preferred_username}
                    </span>
                  </span>
                ) : (
                  <span className="d-flex flex-column">
                    <img
                      src={`${avatarImage}`}
                      className="ka-avatar-small rounded-circle border"
                      alt="avatar-small"
                    />
                  </span>
                )}
              </Button>
            </NavLink>
          </div>
          <Popover
            popoverClassName={`${Classes.POPOVER_CONTENT_SIZING} ka-popover`}
            interactionKind="hover"
            placement="bottom"
            className="d-none d-md-flex"
            content={
              <div className="d-flex flex-column p-0 m-0">
                <NavLink to="/account/manage" className="text-decoration-none">
                  <Button
                    icon="person"
                    intent={Intent.PRIMARY}
                    fill
                    alignText="left"
                    minimal
                    text="Perfil"
                  />
                </NavLink>
                <Button
                  icon="cog"
                  alignText="left"
                  fill
                  intent={Intent.PRIMARY}
                  minimal={true}
                  onClick={handleClickSettings}
                  text="Configuración"
                />
                <Divider />
                <NavLink
                  to={{
                    pathname: `${ApplicationPaths.LogOut}`,
                    state: { local: true },
                  }}
                  className="text-decoration-none"
                >
                  <Button
                    icon="log-out"
                    alignText="left"
                    intent={Intent.PRIMARY}
                    minimal
                    fill
                    text="Cerrar Sesión"
                  />
                </NavLink>
              </div>
            }
          >
            <NavLink to="/account/manage" className="text-decoration-none">
              <Button
                rightIcon="caret-down"
                intent={Intent.PRIMARY}
                minimal={true}
              >
                {!avatarImage ? (
                  <span className="d-flex flex-column">
                    <span className="text-left bp5-text-small">Hola,</span>
                    <span className="fw-bolder">
                      {user?.preferred_username}
                    </span>
                  </span>
                ) : (
                  <span className="d-flex flex-column">
                    <img
                      src={`${avatarImage}`}
                      className="ka-avatar-small rounded-circle border"
                      alt="avatar-small"
                    />
                  </span>
                )}
              </Button>
            </NavLink>
          </Popover>
        </>
      ) : (
        <>
          <div className="d-flex align-items-center  d-md-none">
            <NavLink
              to={ApplicationPaths.Login}
              className="text-decoration-none"
            >
              <Button
                className="d-inline"
                alignText="center"
                intent={Intent.PRIMARY}
                minimal={true}
                large
              >
                <span className="d-flex flex-sm-column text-center align-items-center justify-content-center">
                  <Icon className="mt-1" icon="log-in" size={16} />
                  <small className="fw-bolder ms-2 ms-sm-0">Ingresar</small>
                </span>
              </Button>
            </NavLink>
          </div>
          <Popover
            popoverClassName={`${Classes.POPOVER_CONTENT_SIZING} ka-popover`}
            className="d-none d-md-flex"
            content={
              <div className="p-3">
                <div className="d-flex flex-column align-items-center">
                  <NavLink
                    to={ApplicationPaths.Login}
                    className="text-decoration-none"
                  >
                    <Button intent="primary" text="Iniciar Sesión" />
                  </NavLink>

                  <div className="d-flex ka-small-register-link mt-1">
                    <span className="align-self-center text-center">
                      ¿No tienes cuenta?
                    </span>
                    <NavLink
                      to={ApplicationPaths.Register}
                      className="text-decoration-none"
                    >
                      <Button
                        small
                        minimal
                        intent={Intent.PRIMARY}
                        className="fw-bolder ms-1 text-center"
                        text="Regístrate Aquí"
                      />
                    </NavLink>
                  </div>
                </div>
                <Divider />
                <Button
                  icon="cog"
                  fill
                  intent={Intent.PRIMARY}
                  minimal={true}
                  onClick={handleClickSettings}
                  text="Configuración"
                  alignText="left"
                />
              </div>
            }
            interactionKind="hover"
            placement="bottom"
          >
            <NavLink
              to={ApplicationPaths.Login}
              className="text-decoration-none"
            >
              <Button
                className="align-items-center justify-content-center"
                rightIcon="caret-down"
                intent={Intent.PRIMARY}
                minimal={true}
                large
              >
                <span className="d-flex text-center flex-column align-items-center justify-content-center">
                  <Icon className="mt-1" icon="log-in" size={16} />
                  <small className="fw-bolder">Ingresar</small>
                </span>
              </Button>
            </NavLink>
          </Popover>
        </>
      )}
    </>
  );
};

export default NavMenuLogin;

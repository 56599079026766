import {
  OverlayToaster,
  Position,
  Toast,
  Toaster,
  ToastProps,
} from "@blueprintjs/core";
import React, { useCallback, useContext, useState } from "react";
import { IToastProvider } from "./types/IToastProvider";

const ToastContext = React.createContext<IToastProvider>({} as IToastProvider);

export const ToastProvider = ({ children }) => {
  const [state] = useState({ toasts: [] });
  let toaster: Toaster;
  const refHandlers = {
    toaster: (ref: Toaster) => {
      toaster = ref;
    },
  };

  const addToast = (props: ToastProps, key?: string) => {
    toaster.show(props, key);
  };

  return (
    <>
      <OverlayToaster
        position={Position.TOP}
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref={useCallback(refHandlers.toaster as any, [])}
      >
        {state.toasts.map((toast) => (
          <Toast {...toast} />
        ))}
      </OverlayToaster>
      <ToastContext.Provider value={{ addToast }}>
        {children}
      </ToastContext.Provider>
    </>
  );
};

export const useToastContext = () => {
  return useContext(ToastContext);
};

import useGetCurrentUserProfile from "api/account/useGetCurrentUserProfile";
import useGetDashboard from "api/dashboard/useGetDashboard";
import React, { useContext, useReducer } from "react";
import { useEffect } from "react";
import { DashboardActions } from "reducers/actions/DashboardActions";
import { GlobalActions } from "reducers/actions/GlobalActions";
import dashboardReducer from "reducers/dashboardReducer";
import { useGlobalContext } from "./GlobalContext";
import {
  IDashboardProvider,
  IDashboardProviderState,
} from "./types/IDashboardProvider";

const DashboardContext = React.createContext<IDashboardProvider>(
  {} as IDashboardProvider
);

export const DashboardProvider = ({ children }) => {
  const [dashboardState, dashboardDispatcher] = useReducer(dashboardReducer, {
    learningStreak: 0,
    weeklyFlashcardDoneReviews: 0,
    maximumLearningStreak: 0,
    isLoading: true,
  } as IDashboardProviderState);

  const provider: IDashboardProvider = {
    dashboardState,
    dashboardDispatcher,
  };

  const { execute } = useGetDashboard({ noDisplayLoading: true });
  const { execute: executeGetCurrentUserProfile } = useGetCurrentUserProfile({
    noDisplayLoading: true,
  });

  const { globalState, globalDispatcher } = useGlobalContext();
  const { isAuthenticated } = globalState.userProfile;

  useEffect(() => {
    if (isAuthenticated) {
      const getDashboardGeneralSection = async () => {
        const result = await execute();

        if (!result.error) {
          dashboardDispatcher({
            type: DashboardActions.SET_DASHBOARD,
            payload: { ...result.response, isLoading: false },
          });
        }
      };

      const loadUserProfile = async () => {
        const result = await executeGetCurrentUserProfile();

        if (!result.error) {
          globalDispatcher({
            type: GlobalActions.SET_USER,
            payload: {
              ...globalState.userProfile,
              ...result.response,
            },
          });
        }
      };

      getDashboardGeneralSection();
      loadUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <DashboardContext.Provider value={provider}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

import LoadingOverlay from "components/shared/LoadingOverlay";
import { lazy, Suspense, useMemo } from "react";

const LoadFlashcardsRoutes = () => {
  const FlashcardsRoutes = useMemo(
    () => lazy(() => import("../FlashcardsRoutes")),
    []
  );

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <FlashcardsRoutes />
    </Suspense>
  );
};

export default LoadFlashcardsRoutes;

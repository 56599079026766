import ISuccessProps from "./types/ISuccessProps";
import { Button, H4, Icon } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { ScrollToTopOnMount } from "./Utils";

const Success = (props: ISuccessProps) => {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <Icon
          icon={props.icon ?? "tick-circle"}
          intent={props.intent ?? "success"}
          className="mb-3"
          size={97}
        />
        <H4 className="mb-4 text-center">{props.message}</H4>
        {props.detailMessage && (
          <div className="mb-4 text-center">{props.detailMessage}</div>
        )}
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button
            minimal
            large
            intent="primary"
            text="Volver al inicio"
            icon="home"
          />
        </Link>
      </div>
    </>
  );
};
export default Success;

import ICustomFlashcard from "components/custom-flashcards/types/ICustomFlashcard";
import IDeck from "components/decks/types/IDeck";
import { IDashboardProviderState } from "contexts/types/IDashboardProvider";
import ILearningStreak from "contexts/types/ILearningStreak";

export enum DashboardActions {
  SET_DASHBOARD = "SET_DASHBOARD",
  SET_LAST_DECK = "SET_LAST_DECK",
  SET_LAST_CUSTOM_FLASHCARD = "SET_LAST_CUSTOM_FLASHCARD",
  SET_LEARNING_STREAK = "SET_LEARNING_STREAK",
}

export type DashboardAction =
  | {
      type: DashboardActions.SET_DASHBOARD;
      payload: IDashboardProviderState;
    }
  | {
      type: DashboardActions.SET_LAST_DECK;
      payload: IDeck;
    }
  | {
      type: DashboardActions.SET_LAST_CUSTOM_FLASHCARD;
      payload: ICustomFlashcard;
    }
  | {
      type: DashboardActions.SET_LEARNING_STREAK;
      payload: ILearningStreak;
    };

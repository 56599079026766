import LoadingOverlay from "components/shared/LoadingOverlay";
import { lazy, Suspense, useMemo } from "react";

const LoadSubscriptionsRoutes = () => {
  const SubscriptionsRoutes = useMemo(
    () => lazy(() => import("../SubscriptionsRoutes")),
    []
  );

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <SubscriptionsRoutes />
    </Suspense>
  );
};

export default LoadSubscriptionsRoutes;

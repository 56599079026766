import { Intent, Button, Divider } from "@blueprintjs/core";
import { useGlobalContext } from "contexts/GlobalContext";
import { IGlobalProvider } from "contexts/types/IGlobalProvider";
import Roles from "core/types/enums/Roles";
import { NavLink } from "react-router-dom";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import AdminMenuItems from "./admin/AdminMenuItems";
import INavMenuItemsProps from "./types/INavMenuItemsProps";

export const NavMenuItems = (props: INavMenuItemsProps) => {
  const { closeDrawer, drawerIsOpen, handleClickSettings } = props;
  const { globalState }: IGlobalProvider = useGlobalContext();
  const { isAuthenticated } = globalState.userProfile!;
  const { user } = globalState.userProfile;

  const closeParentDrawer = () => {
    if (closeDrawer) {
      closeDrawer();
    }
  };

  return (
    <>
      {!isAuthenticated ? (
        <>
          <div className="d-md-none">
            <div className="d-flex flex-column align-items-center">
              <NavLink
                to={ApplicationPaths.Login}
                className="text-decoration-none"
              >
                <Button
                  intent="primary"
                  onClick={closeParentDrawer}
                  text="Iniciar Sesión"
                />
              </NavLink>

              <div className="d-flex ka-small-register-link text-center mt-1">
                <small className="align-self-center">¿No tienes cuenta?</small>
                <NavLink
                  className="text-decoration-none"
                  to={ApplicationPaths.Register}
                >
                  <Button
                    small
                    minimal
                    alignText="center"
                    intent={Intent.PRIMARY}
                    onClick={closeParentDrawer}
                    className="fw-bolder mx-0"
                    text="Regístrate Aquí"
                  />
                </NavLink>
              </div>
            </div>
            <Divider />
          </div>
        </>
      ) : (
        <></>
      )}
      {isAuthenticated ? (
        <>
          {user?.role === Roles.individual_free ? (
            <>
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex ka-small-subscribe mb-1">
                  <span className="align-self-center">¿No estás suscrito?</span>
                </div>
                <NavLink
                  to={"/subscriptions/subscribe-pro"}
                  className="text-decoration-none mb-2"
                >
                  <Button
                    intent="primary"
                    onClick={closeParentDrawer}
                    text="Kotoba Anki Pro"
                    icon="star"
                  />
                </NavLink>
              </div>
              <Divider />
            </>
          ) : (
            <></>
          )}

          <NavLink
            to="/my-flashcards"
            className="text-decoration-none d-flex flex-column "
          >
            <Button
              icon="grid-view"
              intent={Intent.PRIMARY}
              large={drawerIsOpen}
              alignText="left"
              minimal={true}
              onClick={closeParentDrawer}
              text="Mis Flashcards"
            />
          </NavLink>
          <NavLink
            to="/decks"
            className="text-decoration-none d-flex flex-column "
          >
            <Button
              icon="layers"
              intent={Intent.PRIMARY}
              large={drawerIsOpen}
              alignText="left"
              minimal={true}
              onClick={closeParentDrawer}
              text="Mazos"
            />
          </NavLink>
        </>
      ) : (
        <></>
      )}
      <NavLink
        to="/flashcards"
        className="text-decoration-none d-flex flex-column "
      >
        <Button
          alignText="left"
          icon="applications"
          intent={Intent.PRIMARY}
          large={drawerIsOpen}
          minimal={true}
          onClick={closeParentDrawer}
          text="Flashcards"
        />
      </NavLink>
      <NavLink
        to="/flashcards-review"
        className="text-decoration-none d-flex flex-column "
      >
        <Button
          icon="book"
          intent={Intent.PRIMARY}
          large={drawerIsOpen}
          alignText="left"
          minimal={true}
          onClick={closeParentDrawer}
          text="Repaso"
        />
      </NavLink>
      <NavLink
        to="/contact-feedback"
        className="text-decoration-none d-flex flex-column"
      >
        <Button
          icon="headset"
          intent={Intent.PRIMARY}
          large={drawerIsOpen}
          alignText="left"
          minimal={true}
          onClick={closeParentDrawer}
          text="Contacto"
        />
      </NavLink>
      {isAuthenticated ? (
        <NavLink
          to="/account/manage"
          className="text-decoration-none d-flex flex-column"
        >
          <Button
            icon="person"
            intent={Intent.PRIMARY}
            large={drawerIsOpen}
            alignText="left"
            minimal={true}
            onClick={closeParentDrawer}
            text="Perfil"
          />
        </NavLink>
      ) : (
        <></>
      )}

      <Button
        icon="cog"
        intent={Intent.PRIMARY}
        large
        minimal={true}
        onClick={handleClickSettings}
        text="Configuración"
        alignText="left"
      />
      {/* {(!isAuthenticated ||
        (isAuthenticated && user?.role === Roles.individual_free)) && (
        <NavLink
          to="/donate"
          className={`text-decoration-none ${
            isAuthenticated ? "d-none d-md-flex" : "d-flex"
          } flex-column `}
        >
          <Button
            icon="bank-account"
            intent="primary"
            large={drawerIsOpen}
            alignText="left"
            minimal={true}
            onClick={closeParentDrawer}
            text="Donar"
          />
        </NavLink>
      )} */}
      {isAuthenticated ? (
        <div className="d-md-none">
          <Divider />
          {/* {user?.role === Roles.individual_free && (
            <NavLink
              to="/donate"
              className="text-decoration-none d-flex flex-column "
            >
              <Button
                icon="bank-account"
                intent="primary"
                large={drawerIsOpen}
                alignText="left"
                minimal={true}
                onClick={closeParentDrawer}
                text="Donar"
              />
            </NavLink>
          )} */}
          <NavLink
            to={{
              pathname: `${ApplicationPaths.LogOut}`,
              state: { local: true },
            }}
            className="text-decoration-none d-flex flex-column"
          >
            <Button
              icon="log-out"
              intent={Intent.PRIMARY}
              onClick={closeParentDrawer}
              minimal
              large
              alignText="left"
              text="Cerrar Sesión"
            />
          </NavLink>
        </div>
      ) : (
        <></>
      )}
      {user?.role === Roles.admin && <AdminMenuItems {...props} />}
    </>
  );
};

export default NavMenuItems;

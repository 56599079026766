import { authTokenInterceptor } from "../interceptors/authTokenInterceptor";
import { headers, HttpAxios } from "./httpAxios";

export class HttpKotobaAnki extends HttpAxios {
  constructor() {
    super({
      baseURL: process.env.REACT_APP_KOTOBA_ANKI_API_DOMAIN,
      headers: headers,
    });

    this.service.interceptors.request.use(authTokenInterceptor);
  }
}

export default new HttpKotobaAnki();

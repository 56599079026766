import LoadingOverlay from "components/shared/LoadingOverlay";
import { lazy, Suspense, useMemo } from "react";

const LoadCustomFlashcardsRoutes = () => {
  const CustomFlashcardsRoutes = useMemo(
    () => lazy(() => import("../CustomFlashcardsRoutes")),
    []
  );

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <CustomFlashcardsRoutes />
    </Suspense>
  );
};

export default LoadCustomFlashcardsRoutes;

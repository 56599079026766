import { RadioGroup, Radio, Switch, Icon, FormGroup } from "@blueprintjs/core";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { IGlobalProvider } from "../../../contexts/types/IGlobalProvider";
import { GlobalActions } from "../../../reducers/actions/GlobalActions";

const GeneralSettings = () => {
  const { globalState, globalDispatcher }: IGlobalProvider = useGlobalContext();

  const handleThemeChange = (event: React.FormEvent<HTMLInputElement>) => {
    globalDispatcher({
      type: GlobalActions.SET_DARK_THEME,
      payload:
        (event.target as HTMLInputElement).value === "true" ? true : false,
    });
  };

  const handleNoSoundChange = () => {
    globalDispatcher({
      type: GlobalActions.TOGGLE_NO_SOUND,
    });
  };

  return (
    <>
      <RadioGroup
        inline={true}
        label="Tema"
        name="theme"
        onChange={handleThemeChange}
        selectedValue={globalState.userSettings.isDarkMode ? "true" : "false"}
      >
        <Radio
          labelElement={
            <span>
              <Icon icon="flash" intent="primary"></Icon> Claro
            </span>
          }
          value={"false"}
        />
        <Radio
          labelElement={
            <span>
              <Icon icon="moon" intent="primary"></Icon> Oscuro
            </span>
          }
          value={"true"}
        />
      </RadioGroup>
      <FormGroup className="mt-2" label="Sonido">
        <Switch
          checked={!globalState.userSettings.noSound}
          onChange={handleNoSoundChange}
          innerLabelChecked="Activo"
          innerLabel="Inactivo"
          large
        />
      </FormGroup>
    </>
  );
};

export default GeneralSettings;

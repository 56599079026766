import { lazy, Suspense, useMemo } from "react";
import LoadingOverlay from "components/shared/LoadingOverlay";

const LoadPrivacyPolicyMain = () => {
  const PrivacyPolicyMain = useMemo(
    () => lazy(() => import("components/shared/PrivacyPolicyMain")),
    []
  );

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <PrivacyPolicyMain />
    </Suspense>
  );
};

export default LoadPrivacyPolicyMain;

import apiConstants from "./types/AccountConstants";
import useApiKotobaAnkiGet from "../shared/useApiKotobaAnkiGet";
import { ICurrentUserProfileOutput } from "./types/GetCurrentUserProfileDtos";
import { IApiKotobaAnkiOptions } from "api/shared/types/IApiKotobaAnkiOptions";

export default function useGetCurrentUserProfile(
  options?: IApiKotobaAnkiOptions
) {
  return useApiKotobaAnkiGet<undefined, ICurrentUserProfileOutput>(
    `${apiConstants.baseUrl}/GetCurrentUserProfile`,
    options
  );
}

import { Button, NonIdealState } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { ScrollToTopOnMount } from "./Utils";

const Notfound = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <NonIdealState
        icon={"search"}
        title="Página no encontrada"
        description="No se ha encontrado la página solicitada.
Intente cambiar la url"
        action={
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button
              minimal
              large
              intent="primary"
              text="Volver al inicio"
              icon="home"
            />
          </Link>
        }
      />
    </>
  );
};

export default Notfound;

import React, { useContext, useEffect, useReducer } from "react";
import { IFlashcardsProvider } from "./types/IFlashcardsProvider";
import flashcardsReducer, {
  getFiltersFromLocalStorage,
} from "../reducers/flashcardsReducer";
import useLoadFlashcards from "../api/flashcards/useLoadFlashcards";
import { FlashcardsActions } from "../reducers/actions/FlashcardsActions";
import IPaginated from "../core/types/IPaginated";

const FlashcardsContext = React.createContext<IFlashcardsProvider>(
  {} as IFlashcardsProvider
);

export const FlashcardsProvider = ({ children }) => {
  const { execute, data } = useLoadFlashcards({
    noDisplayLoading: true,
  });

  const [flashcardsState, flashcardsDispatcher] = useReducer(
    flashcardsReducer,
    {
      filters: getFiltersFromLocalStorage(),
      flashcards: [],
      infiniteScrollKey: 1,
      hasMore: true,
    }
  );

  useEffect(() => {
    execute(flashcardsState.filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flashcardsState.filters]);

  useEffect(() => {
    flashcardsDispatcher({
      type: FlashcardsActions.ADD_FLASHCARDS,
      payload: data,
    });

    if (
      (data &&
        !data.length &&
        (flashcardsState.filters as IPaginated)._page >= 1) ||
      (data &&
        data.length &&
        data.length < (flashcardsState.filters as IPaginated)._limit)
    ) {
      flashcardsDispatcher({
        type: FlashcardsActions.STOP_INFINTE_SCROLL,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const provider: IFlashcardsProvider = {
    flashcardsState,
    flashcardsDispatcher,
  };

  return (
    <FlashcardsContext.Provider value={provider}>
      {children}
    </FlashcardsContext.Provider>
  );
};

export const useFlashcardsContext = () => {
  return useContext(FlashcardsContext);
};

import { useState } from "react";
import apiService from "./httpApiKotobaAnki";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { IGlobalProvider } from "../../contexts/types/IGlobalProvider";
import { AxiosRequestConfig } from "axios";
import { GlobalActions } from "../../reducers/actions/GlobalActions";
import { IApiKotobaAnkiOptions } from "./types/IApiKotobaAnkiOptions";
import querystring from "querystring";
import IHttpApiResponse from "./types/IHttpResponse";
import { buildParams } from "../../core/utils";
import { useToastContext } from "../../contexts/ToastContext";
import { restErrorHandler } from "./restErrorHandler";

// P = parameter type
// R Result/Response type
export default function useApiKotobaAnkiGet<P = any, R = any>(
  url: string,
  options?: IApiKotobaAnkiOptions
) {
  const [state, setState] = useState<{
    data: R | null | undefined;
    error: any;
    loading: boolean;
  }>({
    data: undefined,
    error: undefined,
    loading: false,
  });
  const { globalDispatcher }: IGlobalProvider = useGlobalContext();
  const { addToast } = useToastContext();

  return {
    ...state,
    execute: (params?: P, config?: AxiosRequestConfig) => {
      try {
        setState({ ...state, loading: true });
        if (!options?.noDisplayLoading) {
          globalDispatcher({ type: GlobalActions.BEGIN_API_CALL });
        }
        const paramBuild = buildParams(params);
        const urlFinal = !paramBuild
          ? url
          : `${url}${
              url?.indexOf("?") !== -1 ? "&" : "?"
            }${querystring.stringify(paramBuild)}`;

        return apiService
          .get<R>(urlFinal, config)
          .then((response: R) => {
            setState({ data: response, error: undefined, loading: false });
            if (!options?.noDisplayLoading) {
              globalDispatcher({
                type: GlobalActions.API_CALL_SUCCESS,
              });
            }
            return { response } as IHttpApiResponse<R>;
          })
          .catch((error: any) => {
            setState({ data: undefined, error, loading: false });
            if (!options?.noDisplayLoading) {
              globalDispatcher({
                type: GlobalActions.API_CALL_ERROR,
              });
            }
            return { error } as IHttpApiResponse<R>;
          });
      } catch (error) {
        setState({ ...state, loading: false });
        if (!options?.noDisplayLoading) {
          globalDispatcher({ type: GlobalActions.API_CALL_ERROR });
        }

        restErrorHandler(error, addToast);

        return new Promise<IHttpApiResponse<R>>((resolve) =>
          resolve({ error } as IHttpApiResponse<R>)
        );
      }
    },
  };
}

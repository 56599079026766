import { LoadFlashcardParams } from "../../api/flashcards/types/LoadFlashcardParams";
import IFlashcard from "../../components/flashcards/types/IFlashcard";

export enum FlashcardsActions {
  LOAD_FILTERS = "LOAD_FILTERS",
  SET_FILTERS = "SET_FILTERS",
  ADD_FLASHCARDS = "ADD_FLASHCARDS",
  STOP_INFINTE_SCROLL = "STOP_INFINTE_SCROLL",
  RESTART_INFINTE_SCROLL = "RESTART_INFINTE_SCROLL",
}

export type FlashcardsAction =
 | { type: FlashcardsActions.LOAD_FILTERS }
  | { type: FlashcardsActions.STOP_INFINTE_SCROLL }
  | { type: FlashcardsActions.RESTART_INFINTE_SCROLL }
  | { type: FlashcardsActions.SET_FILTERS; payload: LoadFlashcardParams }
  | {
      type: FlashcardsActions.ADD_FLASHCARDS;
      payload: IFlashcard[] | null | undefined;
    };

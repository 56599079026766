import { Classes, Overlay, Spinner } from "@blueprintjs/core";
import ILoadingOverlay from "./types/ILoadingOverlay";

const LoadingOverlay = ({ showLoading, message }: ILoadingOverlay) => {
  return (
    <Overlay
      className={Classes.OVERLAY_SCROLL_CONTAINER}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      isOpen={showLoading}
    >
      <div className="w-100 h-100">
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <Spinner intent="primary" size={50} />
          <p style={{ color: "white" }}>Por favor espere ...</p>
          {message ? (
            typeof message === "string" ? (
              <>
                <p style={{ color: "white" }}>{message}</p>
              </>
            ) : (
              message
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </Overlay>
  );
};

export default LoadingOverlay;

import IFlashcard from "../../components/flashcards/types/IFlashcard";
import { LoadFlashcardParams } from "./types/LoadFlashcardParams";
import useApiKotobaAnkiGet from "../shared/useApiKotobaAnkiGet";
import apiConstants from "./types/FlashcardConstants";
import { IApiKotobaAnkiOptions } from "../shared/types/IApiKotobaAnkiOptions";

export default function useLoadFlashcards(options?: IApiKotobaAnkiOptions) {
  return useApiKotobaAnkiGet<LoadFlashcardParams, IFlashcard[]>(
    apiConstants.baseUrl,
    options
  );
}

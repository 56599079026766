import { Route, Switch } from "react-router";
import { ApplicationPaths } from "../components/shared/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./ApiAuthorizationRoutes";
import Home from "../components/shared/Home";
import React from "react";
import Notfound from "../components/shared/Notfound";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import AuthorizeRoute from "components/shared/api-authorization/AuthorizeRoute";
import Roles from "core/types/enums/Roles";
import LoadTermsConditionsMain from "components/shared/loaders/LoadTermsConditionsMain";
import LoadPrivacyPolicyMain from "components/shared/loaders/LoadPrivacyPolicyMain";
import LoadCustomFlashcardsRoutes from "./loaders/LoadCustomFlashcardsRoutes";
import LoadFlashcardsRoutes from "./loaders/LoadFlashcardsRoutes";
import LoadDecksRoutes from "./loaders/LoadDecksRoutes";
import LoadFlashcardsReviewRoutes from "./loaders/LoadFlashcardsReviewRoutes";
import LoadSubscriptionsRoutes from "./loaders/LoadSubscriptionsRoutes";
import LoadAccountRoutes from "./loaders/LoadAccountRoutes";
import LoadContactFeedback from "./loaders/LoadContactFeedback";
import LoadAdminRoutes from "./loaders/LoadAdminRoutes";

export const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <AuthorizeRoute
        path="/my-flashcards"
        component={LoadCustomFlashcardsRoutes}
      />
      <Route path="/flashcards" component={LoadFlashcardsRoutes} />
      <AuthorizeRoute path="/decks" component={LoadDecksRoutes} />
      <Route path="/flashcards-review" component={LoadFlashcardsReviewRoutes} />
      <Route path="/subscriptions" component={LoadSubscriptionsRoutes} />
      <Route path="/account" component={LoadAccountRoutes} />
      <Route path="/terms" component={LoadTermsConditionsMain} />
      <Route path="/privacy" component={LoadPrivacyPolicyMain} />
      <Route exact path="/contact-feedback">
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          language={"es"}
        >
          <LoadContactFeedback />
        </GoogleReCaptchaProvider>
      </Route>
      <Route
        path={ApplicationPaths.ApiAuthorizationPrefix}
        component={ApiAuthorizationRoutes}
      />
      <AuthorizeRoute
        path="/admin"
        component={LoadAdminRoutes}
        roles={[Roles.admin]}
      />
      <Route component={Notfound} />
    </Switch>
  );
};

export default AppRoutes;

import LoadingOverlay from "components/shared/LoadingOverlay";
import { lazy, Suspense, useMemo } from "react";

const LoadContactFeedback = () => {
  const ContactFeedback = useMemo(
    () =>
      lazy(() => import("../../components/contact-feedback/ContactFeedback")),
    []
  );

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <ContactFeedback />
    </Suspense>
  );
};

export default LoadContactFeedback;

import {
  ApplicationPaths,
  QueryParameterNames,
} from "components/shared/api-authorization/ApiAuthorizationConstants";
import IFlashcardConfiguration from "../components/flashcards/types/IFlashcardConfiguration";
import IAttachment from "./types/IAttachment";
import { dateFormat, dateTimeFormat } from "./types/Constants";
import moment from "moment";

export const buildParams = (params: any) => {
  if (params) {
    const newParams = { ...params };
    for (const key in newParams) {
      newParams[key] =
        newParams[key] instanceof Date
          ? moment(newParams[key] as Date).format()
          : newParams[key];

      if (
        newParams[key] === "" ||
        newParams[key] === null ||
        newParams[key] === undefined
      ) {
        delete newParams[key];
      }
    }
    return Object.keys(newParams).length > 0 ? newParams : undefined;
  }
  return undefined;
};

export const calculateFrontCardTextSize = (
  width: number,
  content: string,
  configuration?: IFlashcardConfiguration
): string => {
  let result: number = 0;
  const marginAndPadding =
    width < 110 ? 20 : width < 220 && width >= 110 ? 23 : 55; //64
  width = width - marginAndPadding;

  if (!content) {
    return "0px";
  }

  if (configuration && configuration.nameSize) {
    return `${Math.round(((configuration.nameSize + 2) * width) / 100)}px`;
  }

  const characterNumber = content.length;
  const ajustPercentage = characterNumber === 1 ? 35 : 5; //45 : 12;

  const actualwidth = width - Math.round((ajustPercentage * width) / 100);

  switch (true) {
    case characterNumber <= 5:
      result =
        actualwidth /
        (characterNumber <= 2
          ? characterNumber
          : characterNumber === 3
          ? characterNumber - 0.3
          : characterNumber === 4
          ? characterNumber - 0.6
          : characterNumber - 0.9);
      break;
    case characterNumber > 5 && characterNumber <= 10:
      result = actualwidth / 4.5;
      break;
    case characterNumber > 10 && characterNumber <= 20:
      result = actualwidth / 10;
      break;
    default:
      const characterPerLineNumber = Math.round(characterNumber / 2);
      result = actualwidth / characterPerLineNumber;
      break;
  }

  return `${Math.round(result)}px`;
};

export const calculateFrontCardTextSizePercentage = (
  width: number,
  content: string,
  configuration?: IFlashcardConfiguration
): string => {
  let result: number = 0;
  const marginAndPadding = 64;
  width = width - marginAndPadding;
  if (configuration && configuration.nameSize) {
    return `${Math.round((configuration.nameSize * width) / 100)}px`;
  }

  const characterNumber = content.length;
  const ajustPercentage = characterNumber === 1 ? 45 : 12;

  const actualwidth = width - Math.round((ajustPercentage * width) / 100);

  switch (true) {
    case characterNumber <= 5:
      result = actualwidth / characterNumber;
      break;
    case characterNumber > 5 && characterNumber <= 10:
      result = actualwidth / 5;
      break;
    case characterNumber > 10 && characterNumber <= 20:
      result = actualwidth / 10;
      break;
    default:
      const characterPerLineNumber = Math.round(characterNumber / 2);
      result = actualwidth / characterPerLineNumber;
      break;
  }

  return `${Math.round(result)}px`;
};

export const calculateBackCardTextSize = (
  width: number,
  hasImage: boolean,
  content: string,
  isCaption?: boolean,
  configuration?: IFlashcardConfiguration
): string => {
  let result: number = 0;
  const marginAndPadding = 54;
  width = width - marginAndPadding;
  if (content) {
    if (configuration) {
      if (isCaption && configuration.meaningCaptionSize) {
        return `${Math.round(
          (configuration.meaningCaptionSize * width) / 100
        )}px`;
      }

      if (!isCaption && configuration.meaningSize) {
        return `${Math.round((configuration.meaningSize * width) / 100)}px`;
      }
    }

    const characterNumber = content.length;
    const actualwidth = width - Math.round((12 * width) / 100);
    switch (true) {
      case characterNumber <= 3:
        result = Math.round((35 * actualwidth) / 100) / characterNumber;
        break;
      case characterNumber === 4:
        result = Math.round((55 * actualwidth) / 100) / characterNumber;
        break;
      case characterNumber <= 5:
        result = actualwidth / characterNumber;
        break;
      case characterNumber > 5 && characterNumber <= 10:
        result = Math.round((65 * actualwidth) / 100) / 5;
        break;
      case characterNumber > 10 && characterNumber <= 20:
        result = actualwidth / 10;
        break;
      default:
        const characterPerLineNumber = Math.round(characterNumber / 2);
        result = actualwidth / characterPerLineNumber;
        break;
    }
    // }
  }
  return `${Math.round(
    isCaption ? Math.round((75 * result) / 100) : result
  )}px`;
};

export const calculateBackImgMaxHeightSize = (width: number) => {
  return width ? `${Math.round(Math.round((70 * width) / 100))}px` : "0px";
};

export const shuffle = <T extends unknown>(
  array: Array<T> | null | undefined
) => {
  if (array) {
    return array.sort(() => Math.random() - 0.5);
  }
  return new Array<T>();
};

export const enumToArray = (enumValue: { [s: string]: string }) => {
  return Object.keys(enumValue).map((key) => ({
    value: enumValue[key],
    name: key,
  }));
};

export const enumToEnumArray = (enumValue: { [s: string]: string }) => {
  return Object.keys(enumValue).map((key) => enumValue[key]);
};

const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const proccessAttachments = async (fileList?: FileList) => {
  let attachments = [] as IAttachment[];
  if (fileList && fileList.length > 0) {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const resultBase64 = (await getBase64(file)) as string;
      const resultFinal =
        resultBase64.lastIndexOf("base64,") !== -1
          ? resultBase64.substring(resultBase64.lastIndexOf("base64,") + 7)
          : resultBase64;
      attachments.push({
        content: resultFinal,
        filename: file.name,
        type: file.name.lastIndexOf(".") !== -1 ? null : file.type,
        disposition: "attachment",
      } as IAttachment);
    }
  }
  return attachments;
};

export const formatDate = (date: Date) => {
  if (date) {
    return moment(UTCToLocalDate(date)).format(dateFormat);
  }
  return undefined;
};

export const formatDateTime = (date: Date) => {
  if (date) {
    return moment(UTCToLocalDate(date)).format(dateTimeFormat);
  }
  return undefined;
};

export function UTCToLocalDate(date: Date) {
  if (date) {
    return new Date(date);
  }
  return date;
}

export function dateWithoutTime(dateTime: Date) {
  var date = new Date(dateTime.getTime());
  date.setHours(0, 0, 0, 0);
  return date;
}

export function dateAddMonth(dateTime: Date, monthsToAdd: number) {
  if (dateTime) {
    dateTime.setMonth(dateTime.getMonth() + monthsToAdd);
  }
  return dateTime;
}

export const buildLoginRedirectUrl = () => {
  const link = document.createElement("a");
  link.href = window.location.href;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  return `${ApplicationPaths.Login}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURIComponent(returnUrl)}`;
};

export const vibrateCustom = (pattern: VibratePattern) => {
  navigator.vibrate && navigator.vibrate(pattern);
};

export const vibrateTap = () => vibrateCustom(5);

import React, { useMemo } from "react";
import { Container } from "reactstrap";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { IGlobalProvider } from "../../contexts/types/IGlobalProvider";

import { NavMenu } from "./menu/NavMenu";
import LoadingOverlay from "./LoadingOverlay";

export const Layout = (props) => {
  const { globalState }: IGlobalProvider = useGlobalContext();
  const { apiProcessing } = globalState;
  const { isDarkMode } = globalState.userSettings;
  const showLoading = useMemo(() => apiProcessing > 0, [apiProcessing]);

  return (
    <div className={`ka-root ka-app  ${isDarkMode ? "bp5-dark" : ""}`}>
      <NavMenu />
      <Container
        id="ka-layout-container"
        fluid
        className="ka-layout-container px-0"
      >
        {props.children}
      </Container>
      <LoadingOverlay showLoading={showLoading} />
    </div>
  );
};

import {
  Alignment,
  NavbarDivider,
  NavbarGroup,
  Intent,
  Navbar,
  Button,
  Classes,
  NavbarHeading,
  Icon,
  Drawer,
  Dialog,
  ControlGroup,
  InputGroup,
} from "@blueprintjs/core";
import { useState, ChangeEvent, FormEvent } from "react";
import { Container } from "reactstrap";
import { NavLink, useHistory } from "react-router-dom";
import "../../../styles/NavMenu.scss";
import NavMenuItems from "./NavMenuItems";
import { IGlobalProvider } from "../../../contexts/types/IGlobalProvider";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import TabsSettings from "../settings/TabsSettings";
import NavMenuLogin from "./NavMenuLogin";
import { useFlashcardsContext } from "contexts/FlashcardsContext";
import { FlashcardsActions } from "reducers/actions/FlashcardsActions";
import { isKana, isRomaji } from "wanakana";
import { GlobalActions } from "reducers/actions/GlobalActions";

export const NavMenu = () => {
  const { flashcardsState, flashcardsDispatcher } = useFlashcardsContext();
  const pageLimit = 25;

  const history = useHistory();

  const [state, setState] = useState({
    isOpen: false,
    settingsIsOpen: false,
    searchBarOpen: false,
    name: "",
  });
  const { globalState, globalDispatcher }: IGlobalProvider = useGlobalContext();
  const { isDarkMode } = globalState.userSettings;

  const openDrawer = () => {
    setState({ ...state, isOpen: true });
  };

  const closeDrawer = () => {
    setState({ ...state, isOpen: false });
  };

  const handleCloseSettings = () => {
    setState({ ...state, settingsIsOpen: false });
  };

  const handleClickSettings = () => {
    setState({ ...state, settingsIsOpen: true });
  };

  const handleViewMode = () => {
    globalDispatcher({
      type: GlobalActions.SET_DARK_THEME,
      payload: !isDarkMode,
    });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setState((currentState) => {
      return { ...currentState, [e.target.id]: e.target.value };
    });
  };

  const handleNameBlur = () => {
    if (!state.name) {
      setFlashcardsFilter();
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFlashcardsFilter();
    history.push(`/flashcards`);
  };

  const setFlashcardsFilter = () => {
    flashcardsDispatcher({
      type: FlashcardsActions.RESTART_INFINTE_SCROLL,
    });

    flashcardsDispatcher({
      type: FlashcardsActions.SET_FILTERS,
      payload: {
        ...flashcardsState.filters,
        ...(isRomaji(
          state.name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") ?? ""
        )
          ? {
              nameRomaji_like: state.name ?? undefined,
              nameHiragana_like: undefined,
              name_like: undefined,
            }
          : isKana(state.name ?? "")
          ? {
              nameHiragana_like: state.name ?? undefined,
              nameRomaji_like: undefined,
              name_like: undefined,
            }
          : {
              name_like: state.name ?? undefined,
              nameRomaji_like: undefined,
              nameHiragana_like: undefined,
            }),
        _page: 1,
        _limit: pageLimit,
      },
    });
  };

  const handleShowSearchBar = () => {
    setState({ ...state, searchBarOpen: true });
  };

  const handleHideSearchBar = () => {
    setState({ ...state, searchBarOpen: false });
  };

  return (
    <header>
      <Navbar fixedToTop>
        <Container>
          <div className="d-flex justify-content-start">
            <NavbarGroup align={Alignment.LEFT} className="flex-grow-1">
              <Button
                icon="menu"
                intent={Intent.PRIMARY}
                minimal={true}
                large={true}
                className="me-2"
                title="Menu"
                onClick={openDrawer}
              />
              <div
                className={`d-none  ${
                  !state.isOpen ? "d-md-flex align-items-center" : ""
                } `}
              >
                <NavbarHeading>
                  <NavLink to="/">
                    <img
                      src={`/images/logo${isDarkMode ? "-dark" : ""}.svg`}
                      className="ka-logo img-fluid"
                      title="Kotoba Anki"
                      alt="Kotoba Anki"
                    />
                  </NavLink>
                </NavbarHeading>
                <NavbarDivider />
              </div>
              <div className="d-flex flex-grow-1">
                <Button
                  className={`me-1 d-md-none ${
                    !state.searchBarOpen ? "d-none" : ""
                  }`}
                  intent="danger"
                  minimal
                  onClick={handleHideSearchBar}
                  title="Cerrar Búsqueda"
                  icon="cross"
                />
                <form
                  autoComplete="off"
                  className={`flex-grow-1 ${
                    !state.searchBarOpen ? "d-none d-md-inline" : "d-inline"
                  } `}
                  onSubmit={handleSubmit}
                >
                  <ControlGroup>
                    <InputGroup
                      id="name"
                      value={state.name}
                      className="me-2"
                      onChange={handleChange}
                      onBlur={handleNameBlur}
                      fill
                      type="search"
                      placeholder="Buscar Palabra ..."
                      leftIcon="search"
                      rightElement={
                        <Button
                          minimal
                          intent="primary"
                          icon="arrow-right"
                          type="submit"
                        />
                      }
                    />
                  </ControlGroup>
                </form>
              </div>
            </NavbarGroup>
            <NavbarGroup align={Alignment.RIGHT}>
              <NavLink
                to="/"
                className={`text-decoration-none d-md-none ${
                  state.searchBarOpen ? "d-none" : ""
                }`}
                title="Home"
              >
                <Button
                  className="d-inline"
                  alignText="center"
                  intent={Intent.PRIMARY}
                  minimal={true}
                  title="Home"
                  large
                >
                  <span className="d-flex flex-column text-center align-items-center justify-content-center">
                    <Icon className="mt-1" icon="home" size={16} />
                    <small className="fw-bolder d-none d-sm-flex">Inicio</small>
                  </span>
                </Button>
              </NavLink>
              <Button
                className={`d-md-none ${state.searchBarOpen ? "d-none" : ""}`}
                alignText="center"
                minimal
                intent="primary"
                title="Buscar"
                onClick={handleShowSearchBar}
                large
              >
                <span className="d-flex flex-column text-center align-items-center justify-content-center">
                  <Icon className="mt-1" icon="search" size={16} />
                  <small className="fw-bolder d-none d-sm-flex">Buscar</small>
                </span>
              </Button>

              <Button
                minimal
                intent="primary"
                large
                alignText="center"
                onClick={handleViewMode}
                title={isDarkMode ? "Modo Claro" : "Modo Oscuro"}
                className={`${
                  state.searchBarOpen ? "d-none d-md-inline" : "d-inline"
                } `}
              >
                <span className="d-flex flex-column text-center align-items-center justify-content-center">
                  <Icon
                    className="mt-1"
                    icon={isDarkMode ? "flash" : "moon"}
                    size={16}
                  />
                  <small className="fw-bolder d-none d-sm-flex">
                    {isDarkMode ? "Claro" : "Oscuro"}
                  </small>
                </span>
              </Button>

              <NavbarDivider
                className={`${
                  state.searchBarOpen ? "d-none d-md-inline" : "d-inline"
                } `}
              />
              <span
                className={`${
                  state.searchBarOpen ? "d-none d-md-inline" : "d-inline"
                } `}
              >
                <NavMenuLogin handleClickSettings={handleClickSettings} />
              </span>
            </NavbarGroup>
          </div>
        </Container>
      </Navbar>
      <Drawer
        className={`${isDarkMode ? "bp5-dark " : ""} ka-drawer`}
        position="left"
        isOpen={state.isOpen}
        onClose={closeDrawer}
      >
        <div className={`${Classes.DRAWER_HEADER}`}>
          <div className="d-flex flex-fill">
            <NavLink to="/" onClick={closeDrawer}>
              <img
                src={`/images/logo${isDarkMode ? "-dark" : ""}.svg`}
                className="ka-logo ms-1 my-1 img-fluid"
                title="Kotoba Anki"
                alt="Kotoba Anki"
              />
            </NavLink>
          </div>
          <div className="d-flex">
            <Button icon="small-cross" minimal onClick={closeDrawer} />
          </div>
        </div>
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            <div className="d-flex flex-column">
              <NavMenuItems
                closeDrawer={closeDrawer}
                drawerIsOpen={state.isOpen}
                handleClickSettings={handleClickSettings}
              />
            </div>
          </div>
        </div>
        <div
          className={`${Classes.DRAWER_FOOTER} d-flex justify-content-center`}
        >
          {" "}
          <small className="my-1 text-center">
            &copy; 2021-{new Date().getFullYear()}, Lienzo Digital{" "}
          </small>
        </div>
      </Drawer>
      <Dialog
        icon="cog"
        isOpen={state.settingsIsOpen}
        onClose={handleCloseSettings}
        title="Configuración"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        className={`${isDarkMode ? "bp5-dark" : ""}`}
      >
        <TabsSettings onClose={handleCloseSettings} />
      </Dialog>
    </header>
  );
};

import { Reducer } from "react";
import { LoadFlashcardParams } from "../api/flashcards/types/LoadFlashcardParams";
import { IFlashcardsProviderState } from "../contexts/types/IFlashcardsProvider";
import {
  FlashcardsAction,
  FlashcardsActions,
} from "./actions/FlashcardsActions";

const flashcardsReducer: Reducer<IFlashcardsProviderState, FlashcardsAction> = (
  state,
  action
) => {
  switch (action.type) {
    case FlashcardsActions.LOAD_FILTERS: {
      return {
        ...state,
        filters: getFiltersFromLocalStorage(),
      };
    }
    case FlashcardsActions.SET_FILTERS:
      const {
        name,
        name_like,
        nameRomaji_like,
        nameHiragana_like,
        _page,
        _limit,
        ...localStorageFilter
      } = action.payload as any;
      setFlashcardsFilterStateToLocalStorage(localStorageFilter);
      return { ...state, filters: action.payload };

    case FlashcardsActions.STOP_INFINTE_SCROLL:
      return { ...state, hasMore: false };

    case FlashcardsActions.RESTART_INFINTE_SCROLL:
      return {
        ...state,
        infiniteScrollKey: state.infiniteScrollKey + 1,
        hasMore: true,
        flashcards: [],
      };

    case FlashcardsActions.ADD_FLASHCARDS:
      return action.payload
        ? {
            ...state,
            flashcards: state.flashcards.concat(action.payload),
          }
        : { ...state };
    default:
      return state;
  }
};

export function getFiltersFromLocalStorage() {
  let result = { _limit: 25, _page: 1 } as LoadFlashcardParams;
  if (localStorage.getItem("flashcardsFilterState")) {
    const flashcardsFilterState: LoadFlashcardParams = JSON.parse(
      localStorage.getItem("flashcardsFilterState") as string
    );
    if (flashcardsFilterState) {
      result = { ...result, ...flashcardsFilterState };
    }
  }
  return result;
}

function setFlashcardsFilterStateToLocalStorage(
  flashcardsFilterState: LoadFlashcardParams
) {
  localStorage.setItem(
    "flashcardsFilterState",
    JSON.stringify(flashcardsFilterState)
  );
}

export default flashcardsReducer;

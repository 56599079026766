import { lazy, Suspense, useMemo } from "react";
import LoadingOverlay from "components/shared/LoadingOverlay";

const LoadTermsConditionsMain = () => {
  const TermsConditionsMain = useMemo(
    () => lazy(() => import("components/shared/TermsConditionsMain")),
    []
  );

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <TermsConditionsMain />
    </Suspense>
  );
};

export default LoadTermsConditionsMain;

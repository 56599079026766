import { lazy, Suspense, useMemo } from "react";
import LoadingOverlay from "components/shared/LoadingOverlay";

const LoadHomeLogged = () => {
  const HomeLogged = useMemo(
    () => lazy(() => import("../HomeLogged")),
    []
  );

  return (
    <Suspense fallback={<LoadingOverlay showLoading={true} />}>
      <HomeLogged />
    </Suspense>
  );
};

export default LoadHomeLogged;

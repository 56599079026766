import React, { useContext, useEffect, useReducer } from "react";
import { IGlobalProvider } from "./types/IGlobalProvider";
import globalReducer, {
  getUserSettingsFromLocalStorage,
} from "../reducers/globalReducer";
import authService from "../api/authService";
import { GlobalActions } from "../reducers/actions/GlobalActions";
import IUserProfile from "./types/User/IUserProfile";

const GlobalContext = React.createContext<IGlobalProvider>(
  {} as IGlobalProvider
);

export const GlobalProvider = ({ children }) => {
  const [globalState, globalDispatcher] = useReducer(globalReducer, {
    userSettings: getUserSettingsFromLocalStorage(),
    userProfile: {} as IUserProfile,
    apiProcessing: 0,
    isLoading: true,
  });

  const provider: IGlobalProvider = { globalState, globalDispatcher };

  useEffect(() => {
    const populateState = async () => {
      const [isAuthenticated, user] = await Promise.all([
        authService.isAuthenticated(),
        authService.getUser(),
      ]);

      globalDispatcher({
        type: GlobalActions.SET_USER,
        payload: {
          isAuthenticated,
          user,
        },
      });
    };
    let _subscription: number | undefined | null = authService.subscribe(() =>
      populateState()
    );
    populateState();
    // returned function will be called on component unmount
    return () => {
      authService.unsubscribe(_subscription);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalContext.Provider value={provider}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

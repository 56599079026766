import { Classes, Button, Tab, Tabs } from "@blueprintjs/core";
import GeneralSettings from "./GeneralSettings";
import FlashcardsSettings from "./FlashcardsSettings";
import ITabsSettingsProps from "./types/ITabsSettingsProps";

const TabsSettings = (props: ITabsSettingsProps) => {
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Tabs
          animate
          id="TabsSettings"
          renderActiveTabPanelOnly={true}
          vertical
        >
          <Tab
            id="general-settings"
            title="General"
            panel={<GeneralSettings />}
          />
          <Tab
            id="flashcards-settings"
            title="Flashcards"
            panel={<FlashcardsSettings />}
          />
        </Tabs>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={props.onClose}>Cerrar</Button>
        </div>
      </div>
    </>
  );
};

export default TabsSettings;

import { IDashboardProviderState } from "contexts/types/IDashboardProvider";
import { Reducer } from "react";
import { DashboardAction, DashboardActions } from "./actions/DashboardActions";

const dashboardReducer: Reducer<IDashboardProviderState, DashboardAction> = (
  state,
  action
) => {
  switch (action.type) {
    case DashboardActions.SET_DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };

    case DashboardActions.SET_LAST_CUSTOM_FLASHCARD:
      return {
        ...state,
        lastCustomFlashcard: action.payload,
      };
    case DashboardActions.SET_LAST_DECK:
      return {
        ...state,
        lastDeck: action.payload,
      };
    case DashboardActions.SET_LEARNING_STREAK:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;

import { Button, Divider, H6 } from "@blueprintjs/core";
import { NavLink } from "react-router-dom";
import INavMenuItemsProps from "../types/INavMenuItemsProps";

export default function AdminMenuItems({
  closeDrawer,
  drawerIsOpen,
  handleClickSettings,
}: INavMenuItemsProps) {
  const closeParentDrawer = () => {
    if (closeDrawer) {
      closeDrawer();
    }
  };
  return (
    <>
      <Divider />
      <H6>Administración</H6>
      <NavLink
        to="/admin/products"
        className="text-decoration-none d-flex flex-column "
      >
        <Button
          alignText="left"
          icon="box"
          intent="primary"
          large={drawerIsOpen}
          minimal={true}
          onClick={closeParentDrawer}
          text="Productos"
        />
      </NavLink>
      <NavLink
        to="/admin/billing-plans"
        className="text-decoration-none d-flex flex-column "
      >
        <Button
          alignText="left"
          icon="dollar"
          intent="primary"
          large={drawerIsOpen}
          minimal={true}
          onClick={closeParentDrawer}
          text="Planes de pago"
        />
      </NavLink>
      <NavLink
        to="/admin/webhook-events"
        className="text-decoration-none d-flex flex-column "
      >
        <Button
          alignText="left"
          icon="exchange"
          intent="primary"
          large={drawerIsOpen}
          minimal={true}
          onClick={closeParentDrawer}
          text="Eventos Webhooks"
        />
      </NavLink>
    </>
  );
}

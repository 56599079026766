import { Switch, FormGroup } from "@blueprintjs/core";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { IGlobalProvider } from "../../../contexts/types/IGlobalProvider";
import { GlobalActions } from "../../../reducers/actions/GlobalActions";

const FlashcardsSettings = () => {
  const { globalState, globalDispatcher }: IGlobalProvider = useGlobalContext();
  const handleShowFuriganaChange = () => {
    globalDispatcher({
      type: GlobalActions.TOGGLE_SHOW_FURIGANA,
    });
  };

  return (
    <>
      <FormGroup className="mt-2" label="Furigana">
        <Switch
          checked={globalState.userSettings.showFuriganaAlways}
          onChange={handleShowFuriganaChange}
          label="Mostrar siempre"
        />
      </FormGroup>
    </>
  );
};

export default FlashcardsSettings;

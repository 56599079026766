import { IApiKotobaAnkiOptions } from "api/shared/types/IApiKotobaAnkiOptions";
import useApiKotobaAnkiGet from "api/shared/useApiKotobaAnkiGet";
import apiConstants from "./types/DashboardConstants";
import { IDashboardOutput } from "./types/GetDashboardDtos";

export default function useDashboardSection(options?: IApiKotobaAnkiOptions) {
  return useApiKotobaAnkiGet<undefined, IDashboardOutput>(
    `${apiConstants.baseUrl}/GetDashboard`,
    options
  );
}

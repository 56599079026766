import * as React from "react";
import { Intent, ToastProps } from "@blueprintjs/core";
import axios from "axios";
import { history } from "../../index";
import { buildLoginRedirectUrl } from "core/utils";

export const restErrorHandler = (
  error: any,
  addToast: (props: ToastProps, key?: string | undefined) => void
) => {
  switch (error?.response?.status) {
    case 400:
      {
        const errors = error?.response?.data?.Errors;

        addToast({
          message:
            errors && errors.length > 0 ? (
              errors.length === 1 ? (
                errors[0]
              ) : (
                <ul>
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </ul>
              )
            ) : (
              "Ha ocurrido un error inesperado"
            ),
          intent: Intent.DANGER,
          icon : "error",
        });
      }
      break;
    case 401:
      const redirectUrl = buildLoginRedirectUrl();
      addToast({
        message: "Tu sesión expiró por inactividad",
        intent: Intent.WARNING,
        icon: "info-sign",
      });
      history.push(redirectUrl);
      break;
    case 500:
      console.error("error!", error);
      const errors = error?.response?.data?.Errors;
      addToast({
        message: errors ? errors[0] : "Ha ocurrido un error inesperado",
        intent: Intent.DANGER,
        icon: "error"
      });
      break;
    default:
      console.error("error!", error);
      if (!axios.isCancel(error)) {
        addToast({
          message: "Ha ocurrido un error inesperado",
          intent: Intent.DANGER,
          icon: "error",
        });
      }
      break;
  }
  return Promise.reject(error);
};

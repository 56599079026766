import IUserProfile from "../../contexts/types/User/IUserProfile";

export enum GlobalActions {
  SET_DARK_THEME = "SET_DARK_THEME",
  TOGGLE_SHOW_FURIGANA = "TOGGLE_SHOW_FURIGANA",
  TOGGLE_NO_SOUND = "TOGGLE_NO_SOUND",
  SET_USER = "SET_USER",
  BEGIN_API_CALL = "BEGIN_API_CALL",
  API_CALL_SUCCESS = "API_CALL_SUCCESS",
  API_CALL_ERROR = "API_CALL_ERROR",
}

export type GlobalAction =
  | { type: GlobalActions.SET_DARK_THEME; payload: boolean }
  | {
      type: GlobalActions.SET_USER;
      payload: IUserProfile;
    }
  | { type: GlobalActions.TOGGLE_SHOW_FURIGANA }
  | { type: GlobalActions.TOGGLE_NO_SOUND }
  | { type: GlobalActions.BEGIN_API_CALL }
  | { type: GlobalActions.API_CALL_SUCCESS }
  | { type: GlobalActions.API_CALL_ERROR };

import IUserSettings from "contexts/types/User/IUserSettings";
import { IGlobalProviderState } from "../contexts/types/IGlobalProvider";
import { GlobalAction, GlobalActions } from "./actions/GlobalActions";

const globalReducer = (state: IGlobalProviderState, action: GlobalAction) => {
  switch (action.type) {
    case GlobalActions.SET_DARK_THEME: {
      const globalState: IGlobalProviderState = {
        ...state,
        userSettings: { ...state.userSettings, isDarkMode: action.payload },
      };
      setUserSettingsToLocalStorage(globalState.userSettings);
      return globalState;
    }
    case GlobalActions.TOGGLE_SHOW_FURIGANA: {
      const globalState: IGlobalProviderState = {
        ...state,
        userSettings: {
          ...state.userSettings,
          showFuriganaAlways: !state.userSettings.showFuriganaAlways,
        },
      };
      setUserSettingsToLocalStorage(globalState.userSettings);
      return globalState;
    }
    case GlobalActions.TOGGLE_NO_SOUND: {
      const globalState: IGlobalProviderState = {
        ...state,
        userSettings: {
          ...state.userSettings,
          noSound: !state.userSettings.noSound,
        },
      };
      setUserSettingsToLocalStorage(globalState.userSettings);
      return globalState;
    }
    case GlobalActions.BEGIN_API_CALL: {
      return { ...state, apiProcessing: state.apiProcessing + 1 };
    }
    case GlobalActions.API_CALL_SUCCESS:
    case GlobalActions.API_CALL_ERROR: {
      return { ...state, apiProcessing: state.apiProcessing - 1 };
    }
    case GlobalActions.SET_USER:
      return {
        ...state,
        isLoading: false,
        userProfile: action.payload,
      };
    default:
      return state;
  }
};

export function getUserSettingsFromLocalStorage() {
  let result: IUserSettings = {
    noSound: false,
    isDarkMode: false,
    showFuriganaAlways: false,
  };
  if (localStorage.getItem("userSettings")) {
    const userSettings: IUserSettings = JSON.parse(
      localStorage.getItem("userSettings") as string
    );
    if (userSettings) {
      result = userSettings;
    }
  }
  return result;
}

function setUserSettingsToLocalStorage(userSettings: IUserSettings) {
  localStorage.setItem("userSettings", JSON.stringify(userSettings));
}

export default globalReducer;

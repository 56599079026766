import React, { useEffect, useState } from "react";
import { Button, Card, Divider, H2, H3, Icon } from "@blueprintjs/core";
import { useGlobalContext } from "contexts/GlobalContext";
import { IGlobalProvider } from "contexts/types/IGlobalProvider";
import { Helmet } from "react-helmet";
import { Link, NavLink, Redirect, useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Footer from "./Footer";
import { ScrollToTopOnMount } from "./Utils";
import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants";
import LoadingOverlay from "./LoadingOverlay";
import { IHomeGenericState } from "./types/IHomeGenericState";

const HomeGeneric = () => {
  const history = useHistory();
  const { globalState }: IGlobalProvider = useGlobalContext();
  const { isAuthenticated } = globalState.userProfile;
  const [state, setState] = useState<IHomeGenericState>({
    isLoading: true,
    isInstalled: false,
    deferredPrompt: undefined,
  });

  useEffect(() => {
    const isInstalled = window.matchMedia(
      "(display-mode: standalone)"
    )?.matches;
    const tempState = { ...state, isLoading: false, isInstalled };

    setState(tempState);

    window.addEventListener("beforeinstallprompt", (event) => {
      setState({ ...tempState, deferredPrompt: event });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const installApp = async () => {
    if (state.deferredPrompt) {
      state.deferredPrompt.prompt();
      const { outcome } = await state.deferredPrompt.userChoice;
      if (outcome === "accepted") {
        setState({ ...state, deferredPrompt: undefined });
      }
    }
  };

  if (state.isLoading || globalState.isLoading) {
    return <LoadingOverlay showLoading={true} />;
  }

  if (!globalState.isLoading && !isAuthenticated && state.isInstalled) {
    return <Redirect to={ApplicationPaths.Login} />;
  }

  return (
    <>
      <Helmet>
        <title>Kotoba Anki</title>
        <meta
          name="description"
          content="Flashcards de vocabulario en japonés. ようこそ！"
        />
      </Helmet>
      <Container className="pt-4">
        <ScrollToTopOnMount />
        <section className="px-1 px-sm-2 px-md-5 d-flex flex-column h-100 ">
          <figure className="p-2 mb-1 text-center">
            <img
              src={`/images/logo${
                globalState.userSettings.isDarkMode ? "-white" : ""
              }.svg`}
              className="ka-jumbotron-logo fluid-right img-fluid"
              title="Kotoba Anki"
              width="2160"
              height="540"
              alt="Bienvenido a Kotoba Anki"
            />
          </figure>
          <div className="d-flex flex-column py-4">
            <h2 className="display-6 mt-2 ka-book-font">ようこそ!</h2>
            <p className="lead mb-1">
              Kotoba Anki es un sitio enfocado al aprendizaje de vocabulario
              japonés mediante flashcards.
            </p>
            <div className="text-center my-3">
              <NavLink
                to="/account/register"
                className="text-decoration-none p-20 "
              >
                <Button
                  intent="primary"
                  icon="new-person"
                  large
                  text="Pruébalo Gratis"
                />
              </NavLink>
            </div>
            <Divider></Divider>
          </div>

          <Row className="mt-3 mb-2">
            <Col sm={6} lg={4} className="pt-4">
              <div className="d-flex flex-column">
                <Link
                  to="/flashcards"
                  className=" ka-welcome-card text-decoration-none "
                >
                  <div className="d-flex flex-column">
                    <Icon icon="applications" size={40}></Icon>
                    <H3 className="mt-3 bp5-icon bp5-icon-applications">
                      Flashcards
                    </H3>
                  </div>
                </Link>
                <p className="bp5-text-large bp5-running-text">
                  Crea y edita tus propias flashcards en pocos pasos. Consulta
                  vocabulario por lección del Minna no Nihongo, nivel del JLPT,
                  o tipo de palabra. También tienes a tu disposición muchas
                  flashcards de vocabulario general.
                </p>
              </div>
            </Col>
            <Col sm={6} lg={4} className="pt-4">
              <div className="d-flex flex-column">
                <Link
                  to="/decks"
                  className=" ka-welcome-card text-decoration-none "
                >
                  <div className="d-flex flex-column">
                    <Icon icon="layers" size={40}></Icon>
                    <H3 className="mt-3 bp5-icon bp5-icon-applications">
                      Mazos
                    </H3>
                  </div>
                </Link>
                <p className="bp5-text-large bp5-running-text">
                  Mantén tus flashcards organizadas en mazos y repasa con ellos.
                  En los mazos puedes tener tantos las flashcards que has
                  creado, como las flashcards generales de Kotoba Anki (sólo
                  usuarios registrados).
                </p>
              </div>
            </Col>
            <Col
              sm={{ size: 8, offset: 2 }}
              lg={{ size: 4, offset: 0 }}
              className="pt-4"
            >
              <div className="d-flex flex-column">
                <Link
                  to="/flashcards-review"
                  className=" ka-welcome-card text-decoration-none "
                >
                  <div className="d-flex flex-column">
                    <Icon icon="book" size={40}></Icon>
                    <H3 className="mt-3 bp5-icon bp5-icon-applications">
                      Repaso
                    </H3>
                  </div>
                </Link>
                <p className="bp5-text-large bp5-running-text">
                  Realiza un breve quiz y conoce qué tanto vocabulario dominas.
                  Puedes usar tanto las flashcards de tus mazos, como las
                  flashcards generales. ¡Cumple la meta semanal y amplia tu
                  vocabulario!.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={{ size: 8, offset: 2 }}>
              <div className="d-flex">
                <img
                  style={{ color: "red" }}
                  src={`/images/pwa_logo.svg`}
                  className="fluid-right img-fluid"
                  title="PWA"
                  width="120"
                  alt="PWA Ready"
                />

                <p className="ms-4 mt-3 bp5-text-large bp5-running-text">
                  ¿Dispositivo móvil, tablet o PC? ¡Lo tenemos cubierto! Kotoba
                  Anki es una "Progressive Web App (PWA)". PWA es una nueva
                  generación de aplicaciones móviles que puede ser instalada
                  desde el navedador, en cualquier dispositivo que lo soporte,
                  sin necesidad de ser descargada desde una app store.
                  {state.deferredPrompt ? " ¡Pruébala!" : ""}
                </p>
              </div>
              {state.deferredPrompt ? (
                <>
                  <div className="d-flex justify-content-center mt-2">
                    <Button
                      intent="primary"
                      icon="import"
                      large
                      text="Instalar App"
                      onClick={installApp}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </section>
      </Container>
      <section className="ka-container-blue-bottom mt-4">
        <div className="d-flex flex-column container py-5">
          <H2 className="text-center ka-form-title mt-3">
            ¿Listo para comenzar? Regístrate ahora{" "}
          </H2>
          <Row>
            <Col
              md={{ size: 10, offset: 1 }}
              lg={{ size: 8, offset: 2 }}
              className="text-center"
            >
              <p>
                Puedes probar Kotoba Anki, gratis, por el tiempo que desees.
                Cuando estés convencido de que es adecuado para tí, puedes
                adquirir la suscripción Pro por el monto especificado abajo.
              </p>
            </Col>
          </Row>

          <div className="d-flex flex-wrap justify-content-center my-4">
            <Card
              className="text-center   align-items-center ka-plan-box"
              onClick={() => history.push("/account/register")}
              interactive
              elevation={0}
            >
              <div className="name light-blue">Kotoba Anki Free</div>
              <div className="plan-pricing-container">
                <div className="plan-pricing glow measurer">
                  <div className="price special-price">
                    <div className="period">MENSUAL</div>
                    <span className="dollar-sign">$</span>1.99
                  </div>
                  <div className="free glow">
                    <span>GRATIS</span>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="subscription-benefits">
                <div className="subscription-benefit odd">
                  Registro instantáneo
                </div>
                <div className="subscription-benefit even">
                  Crea hasta 5 flashcards propias
                </div>
                <div className="subscription-benefit odd">
                  Configura hasta 3 mazos que puedes usar en tus repasos
                </div>
                <div className="subscription-benefit even">
                  Mejora tu suscripción cuando quieras
                  <br />
                  &nbsp;
                </div>
                <div className="subscription-benefit odd">&nbsp;</div>
                <div className="button-container">
                  <NavLink
                    to="/account/register"
                    className="text-decoration-none p-20"
                  >
                    <Button intent={undefined} large text="COMENZAR AHORA" />
                  </NavLink>
                </div>
              </div>
            </Card>
            <Card
              className="text-center align-items-center   ka-plan-box mt-4 mt-md-0 ms-md-4"
              onClick={() => history.push("/subscriptions/subscribe-pro")}
              interactive
              elevation={0}
            >
              <div className="name strong-blue">Kotoba Anki Pro</div>
              <div className="plan-pricing-container">
                <div className="plan-pricing glow">
                  <div className="price special-price">
                    <div className="period">MENSUAL</div>
                    <span className="dollar-sign">$</span>1.99
                  </div>
                </div>
                <div className="plan-pricing glow">
                  <div className="price special-price">
                    <div className="period">ANUAL</div>
                    <span className="dollar-sign">$</span>19.99
                  </div>
                </div>
              </div>
              <Divider />
              <div className="subscription-benefits">
                <div className="subscription-benefit odd">
                  Registro instantáneo
                </div>
                <div className="subscription-benefit even">
                  Crea hasta 2000 flashcards propias
                </div>
                <div className="subscription-benefit odd">
                  Configura hasta 500 mazos que puedes usar en tus repasos
                </div>
                <div className="subscription-benefit even">
                  Modifica cualquier flashcard, general o propia
                </div>
                <div className="subscription-benefit odd">
                  Cancela tu suscripción cuando quieras
                </div>
                <div className="button-container">
                  <NavLink
                    to="/subscriptions/subscribe-pro"
                    className="text-decoration-none p-20"
                  >
                    <Button
                      intent="primary"
                      icon={"star"}
                      large
                      text="COMENZAR AHORA"
                    />
                  </NavLink>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default HomeGeneric;

import React, { Component } from "react";
import { Route, Switch } from "react-router";
import {
  ApplicationPaths,
  LoginActions,
  LogoutActions,
} from "../components/shared/api-authorization/ApiAuthorizationConstants";
import { Login } from "../components/shared/api-authorization/Login";
import { Logout } from "../components/shared/api-authorization/Logout";
import Notfound from "../components/shared/Notfound";

export default class ApiAuthorizationRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route
          path={ApplicationPaths.Login}
          render={() => loginAction(LoginActions.Login)}
        />
        <Route
          path={ApplicationPaths.LoginFailed}
          render={() => loginAction(LoginActions.LoginFailed)}
        />
        <Route
          path={ApplicationPaths.LoginCallback}
          render={() => loginAction(LoginActions.LoginCallback)}
        />
        <Route
          path={ApplicationPaths.Profile}
          render={() => loginAction(LoginActions.Profile)}
        />
        <Route
          path={ApplicationPaths.Register}
          render={() => loginAction(LoginActions.Register)}
        />
        <Route
          path={ApplicationPaths.LogOut}
          render={() => logoutAction(LogoutActions.Logout)}
        />
        <Route
          path={ApplicationPaths.LogOutCallback}
          render={() => logoutAction(LogoutActions.LogoutCallback)}
        />
        <Route
          path={ApplicationPaths.LoggedOut}
          render={() => logoutAction(LogoutActions.LoggedOut)}
        />
        <Route component={Notfound} />
      </Switch>
    );
  }
}

function loginAction(name) {
  return <Login action={name}></Login>;
}

function logoutAction(name) {
  return <Logout action={name}></Logout>;
}

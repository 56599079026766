import { Spinner, H4 } from "@blueprintjs/core";
import React from "react";
import IProcessing from "./types/IProcessing";
import { ScrollToTopOnMount } from "./Utils";

const Processing = ({ text }: IProcessing) => {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <Spinner intent="primary" className="mb-4" size={50} />
        {typeof text === "string" ? <H4>{text}</H4> : text}
      </div>
    </>
  );
};

export default Processing;
